<template>
    <van-nav-bar
            v-if="env"
            :title="navTitle?navTitle:title"
            left-arrow
            @click-left="onClickLeft"
    />
</template>

<script>
import common from './../utils/common.js';

export default {
    name: 'headNav',
    components: {},
    props: {
        config: {
            type: Object,
            default () {
                return {}
            }
        },
        navTitle:{
            type: String,
            default: ""
        }
    },
    data () {
        return {
            title: document.title,
            env: ''
        };
    },
    methods: {
        onClickLeft () {
            this.$router.go(-1);
        }
    },
    created () {
        this.env = common.browserEnvir();
    },
    mounted () {
    },
    computed: {},
    watch: {}
}
</script>

<style scoped lang="less">
    .baseContainer {
        display: flex;
        flex-direction: column;
        height: 100vh;
        .contents {
            flex: 1;
            overflow-y: auto;
        }
    }
</style>
