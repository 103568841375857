export default {
    data () {
        return {
            loading: false,
            finished: false,
            refreshing: false,
            total: 0,
            list: []
        }
    },
    beforeMount () {
    },
    beforeDestroy () {
    },
    mounted () {
    },
    methods: {
        onEnd (total, records) {
            this.list.push(...records);
            this.total = total;
            this.loading = false;
            if (this.list.length >= this.total) {
                this.finished = true;
            }
        },
        onLoad () {
            if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
                this.listQuery.current = 1;
            } else {
                this.listQuery.current += 1;
            }
            this.getList();
        },
        onRefresh () {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        onSearch () {
            this.listQuery.current = 0;
            this.list = [];
            this.finished = false;
            this.loading = true;
            this.onLoad();
        }
    },
    watch: {},
    created () {
        this.listQuery.current = 0;
    }
}
