<template>
    <div class="Interactive cleartop">
        <div id="scrollBox"
             @scroll="handleScrollBox">
            <div class="Interactive_item"
                 :class="{spaeils: item.from === userVo.openId}"
                 v-for="item in messageList" :key="item.ID">
                <p class="messages" v-if="item.payload.operationType">
                    {{item.payload.operationType | operationType(item)}}
                </p>
                <template v-else>
                    <div class="imgs">
                        <baseAvator :url="item.avatar"/>
                    </div>
                    <div class="titles">{{item.nick}}</div>
                    <p class="content">
                        <template v-if="item.type === types.MSG_IMAGE">
                            <img @click="handlePreview(item.payload.imageInfoArray[0].imageUrl)"
                                 :src="item.payload.imageInfoArray[0].imageUrl" alt="">
                        </template>
                        <template v-else>
                            {{item.payload.text}}
                        </template>
                    </p>
                </template>
            </div>
        </div>
        <input style="display: none"
               type="file"
               accept=".jpg,.png,.jpeg,.gif"
               id="imagePicker">
        <div class="msgs"
             @click="handleScrollTop" v-if="msgCount">
            有{{msgCount}}条新消息
        </div>
        <div class="sendOut">
            <van-field @focus="handleScrollTop"
                       v-model="content" placeholder="请输入文字"/>
            <van-icon name="photo-o" @click="handleImage"/>
            <div class="sends" v-if="ready" @click="sendMessage">发送</div>
        </div>
    </div>
</template>

<script>
import TIM from 'tim-js-sdk';
import {mapState} from 'vuex';
import {ImagePreview} from 'vant';

export default {
    name: "Interactive",
    props:{
        liveDetails: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        ...mapState({
            userVo: state => state.users.userVo,
            messageList: state => state.TIM.messageList,
            ready: state => state.TIM.ready
        })
    },
    data() {
        return {
            types: TIM.TYPES,
            content: '',
            isBottom: true,
            msgCount: 0
        }
    },
    filters: {
        operationType(type, item) {
            switch (type) {
            case 255:
                return item.payload.systemMessage;
            case TIM.TYPES.GRP_TIP_MBR_JOIN:
                return '有成员加群';
            case TIM.TYPES.GRP_TIP_MBR_QUIT:
                return '有群成员退群';
            case TIM.TYPES.GRP_TIP_MBR_KICKED_OUT:
                return '有群成员被踢出群';
            default:
                return '';
            }
        }
    },
    methods: {
        handlePreview(url) {
            ImagePreview([url]);
        },
        // 图片选取
        handleImage() {
            let imgs = document.getElementById('imagePicker');

            imgs.onchange = () => {
                this.$store.dispatch('TIM/sendMessage', {
                    type: TIM.TYPES.MSG_IMAGE,
                    file: imgs
                });
            };
            imgs.click();
        },
        sendMessage() {
            if (!this.content) {
                return;
            }
            this.$store.dispatch('TIM/sendMessage', {
                type: TIM.TYPES.MSG_TEXT,
                text: this.content
            });
            this.content = '';
        },
        handleScrollBox($event) {
            let scrollHeight = $event.target.scrollHeight;// div里内容的高度，根据内容的增加和减少变化

            let scrollTop = $event.target.scrollTop;// 滚动条的最上端到，div上端内里框框的高度

            let clientHeight = $event.target.clientHeight;// div内里框框的高度，是死的

            if ((scrollHeight - clientHeight == scrollTop)) {
                console.log('到达底部');
                this.msgCount = 0;
                this.isBottom = true;
            } else {
                this.isBottom && (this.isBottom = false);
            }
        },
        handleScrollTop() {
            let scrollBox = document.getElementById("scrollBox");

            let scrollHeight = scrollBox.scrollHeight;

            scrollBox.scrollTop = scrollHeight;
        }
    },
    created() {
        try {
            this.$store.dispatch('TIM/initTIM', {
                groupID: this.$route.query.streamName,
                liveId: this.$route.query.id
            });
        } catch (e) {
        }
    },
    mounted() {
        // this.$utils.setDocumentTitle(this.liveDetails.name)
    },
    watch: {
        messageList() {
            this.$nextTick(() => {
                if (this.isBottom) {
                    this.handleScrollTop();
                } else {
                    this.msgCount += 1;
                    console.log(this.msgCount);
                }
            });
        },
        liveDetails: {
            handler(val) {
                if (
                    Object.prototype.toString.call(val) === '[Object Object]' &&
                      val.name
                ) {
                    this.$utils.setDocumentTitle(val.name)
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped lang="less">
    .Interactive {
        box-sizing: border-box;
        background: #F7F7F7;
        height: 100%;
    }

    .msgs {
        position: fixed;
        bottom: 96px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100vw;
        height: 80px;
        background-color: #fffbe8;
        line-height: 80px;
        text-align: center;
        color: #ed6a0c;
        cursor: pointer;
        z-index: 2000;
    }

    #scrollBox {
        height: calc(100% - 96px);
        padding: 0 40px;
        overflow-y: auto;
    }

    .Interactive_item {
        margin-top: 60px;
        padding-left: 68px;
        position: relative;
        .messages{
            color: rgba(69, 90, 100, 0.6);
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            margin-left: -68px;
        }
        .imgs {
            position: absolute;
            top: -12px;
            left: 0;
            width: 56px;
            height: 56px;
        }
        .titles {
            font-size: 24px;
            font-weight: 400;
            color: #1A78F9;
            line-height: 34px;
        }
        .content {
            margin-top: 30px;
            font-size: 30px;
            font-weight: 400;
            color: #353535;
            line-height: 30px;
            img {
                max-width: 50%;
            }
        }
        &.spaeils {
            padding-left: 0;
            padding-right: 68px;
            text-align: right;
            .imgs {
                position: absolute;
                left: auto;
                right: 0;
            }
        }
        &:last-child {
            padding-bottom: 40px;
        }
    }

    .sendOut {
        padding: 16px 40px;
        background-color: #fff;
        display: flex;
        .van-cell.van-field {
            padding: 16px 24px;
            background: #F2F1F6;
            border-radius: 8px;
            line-height: 32px;
            height: 64px;
        }
        .van-icon {
            margin: 0 34px;
            line-height: 64px;
            font-size: 36px;
        }
        .sends {
            flex: 0 0 60px;
            width: 60px;
            font-size: 24px;
            font-weight: 400;
            color: #1A78F9;
            line-height: 64px;
        }
    }
</style>