<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav :navTitle="navTitle+peopleNum+'人)'"/>
            <div class="live_contain">
                <template v-if="permission">
                    <div v-if="liveDetail.platformId == '1326816567396151297'" style="height: 100%">
                        <baseLive type="live" :url="url" :liveDetail="liveDetail" v-if="liveDetail.status === liveStatusKey.doing" />
                        <template type="vod" v-else-if="liveDetail.status === liveStatusKey.end">
                            <!-- <baseLive :url="url" v-if="liveDetail.videoUrl" /> -->
                            <baseCKplayer :init="liveDetail" v-if="liveDetail.videoUrl" />
                            <div class="imgPng" v-else>
                                <img src="../../assets/images/end.png" />
                            </div>
                        </template>
                    </div>
                    <div v-else style="height:100%">
                        <baseCKplayer :init="liveDetail" v-if="liveDetail.status === liveStatusKey.doing" />
                        <template v-else-if="liveDetail.status === liveStatusKey.end">
                            <baseCKplayer :init="liveDetail" v-if="liveDetail.videoUrl" />
                            <div class="imgPng" v-else>
                                <img src="../../assets/images/end.png" />
                            </div>
                        </template>
                    </div>
                    <div class="imgPng" v-if="liveDetail.status === liveStatusKey.ready">
                        <img src="../../assets/images/reading.png" />
                    </div>
                </template>
                <div class="imgPng" v-else-if="permission === false">
                    <img src="../../assets/images/jurisdiction.png" />
                </div>
            </div>
            <van-tabs v-model="activeName" class="custom_tabs">
                <van-tab :title="$route.query.courseId ? '课程简介' : '简介'" name="a" />
                <van-tab title="播放列表" name="b" v-if="$route.query.courseId" />
                <van-tab title="互动区" name="c" />
            </van-tabs>
        </template>
        <van-tabs v-model="activeName" class="custom_tabs spaeil">
            <van-tab :title="$route.query.courseId ? '课程简介' : '简介'" name="a">
                <CourseIntro :courseDetail="courseDetail" :liveDetails="liveDetail" />
            </van-tab>
            <van-tab title="播放列表" name="b" v-if="this.$route.query.courseId">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                        <Playlist :liveDetails="liveDetail" :list="list" @callMore="callMore" />
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab title="互动区" name="c" :key="$route.query.id">
                <template v-if="permission">
                    <Interactive :liveDetails="liveDetail" v-if="liveDetail.status === liveStatusKey.doing" />
                    <p v-else-if="liveDetail.status === liveStatusKey.ready" class="van-list__finished-text">直播未开始</p>
                    <p v-else class="van-list__finished-text">直播已结束</p>
                </template>
                <p v-else-if="permission === false" class="van-list__finished-text">无权访问</p>
            </van-tab>
        </van-tabs>
        <van-dialog v-model="show" :show-confirm-button="false" title="密码校验">
            <van-form validate-trigger="onSubmit">
                <van-field v-model="password" name="password" placeholder="请输入直播间密码" :rules="[
                        { required: true},
                        { validator: asyncValidator, message: '直播密码不正确' }
                        ]" />
                <div style="display: flex">
                    <van-button @click="hanleCance" type="default" class="van-dialog__cancel">取消</van-button>
                    <van-button native-type="submit" class="van-dialog__cancel">
                        确认
                    </van-button>
                </div>
            </van-form>
        </van-dialog>
        <base-Socket @returnMsg="returnMsg" ref="baseSocket"></base-Socket>
    </baseContainer>
</template>

<script>
import HeadNav from '../../components/headNav';
import CourseIntro from './live/courseIntro';
import Playlist from './live/playlist';
import Interactive from './live/Interactive';
import CacheTabs from './../../mixin/CacheTabs';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import constant from './../../utils/constant';
import api from './../../api/common';

export default {
    name: "liveDetails",
    components: {
        HeadNav,
        CourseIntro,
        Playlist,
        Interactive
    },
    mixins: [CacheTabs, DropDownRefresh],
    data () {
        return {
            liveStatusKey: constant.liveStatusKey,
            url: '',
            liveTabs: {
                id: this.$route.query.id,
                streamName: this.$route.query.streamName
            },
            courseDetail: {},
            liveDetail: {},
            listQuery: {
                courseId: this.$route.query.courseId,
                current: 1,
                size: 5,
                dateType: 2,
                type: 0,
                needDateRange: false
            },
            show: false,
            password: '',
            permission: null,
            navTitle:"",
            peopleNum:1

        }
    },
    methods: {
        // 实时人数返回
        returnMsg(num) {
            this.peopleNum = num
        },
        callMore (item) {
            this.liveTabs = {
                id: item.id,
                streamName: item.streamName
            };
            this.liveDetail = item;
            this.handleItem(item);
            this.checkLiveAuth(item);
        },
        handleItem (item) {
            if (item.status === constant.liveStatusKey.end) {
                this.url = item.videoUrl;
            } else {
                this.url = item.playUrl;
            }
        },
        // 通过id查询单个课程信息
        getDetails () {
            this.$route.query.courseId && api.getCourseDetails(this.$route.query.courseId).then(res => {
                this.courseDetail = res;
            });
        },
        // 查询学生直播列表(查课程下的直播)
        getList () {
            api.getQueryCourseLive(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        },
        // 获取单个直播（直播页面）
        getLiveDetails () {
            api.getLiveDetailsHom(this.$route.query.id).then(res => {
                if (res) {
                    this.liveDetail = res;
                    this.handleItem(res);
                    this.checkLiveAuth(res);
                    // document.title=res.name;
                    this.$nextTick(() => {
                        this.$refs['baseSocket'].init(res.id)
                        this.navTitle = res.name + "(当前："
                    })
                    this.$utils.setDocumentTitle(res.name)
                }
            });
        },
        // 学生看直播权限验证
        checkLiveAuth (item) {
            this.permission = null;
            if (this.$route.query.noCheck === constant.checkLive.noCheck) {
                this.permission = true;
                return;
            }
            if (item.openFlag) {
                this.permission = true;
            } else if (item.havePassword) {
                this.checkLivePassword(item);
            } else {
                api.checkLiveAuth(item.id).then(() => {
                    this.permission = true;
                }).catch(() => {
                    this.permission = false;
                });
            }
        },
        // 验证直播密码
        checkLivePassword (item) {
            // 公开直播不不要输入密码
            if (!item.openFlag && item.havePassword) {
                this.show = true;
            } else {
                this.permission = true;
            }
        },
        hanleCance () {
            this.show = false;
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else {
                location.reload();
            }
        },
        asyncValidator (val) {
            return new Promise((resolve) => {
                api.checkLivePassword({
                    id: this.liveDetail.id,
                    password: val
                }).then(() => {
                    this.permission = true;
                    this.show = false;
                    this.password = '';
                    resolve(true)
                }).catch(() => {
                    resolve(false);
                });
            });
        }
    },
    mounted () {
        this.getLiveDetails();
        this.getDetails();
    },
    destroyed () {
        this.$store.dispatch('TIM/clearTIM');
    },
    beforeRouteEnter (to, from, next) {
        next();
    }
}
</script>

<style scoped lang="less">
.live_contain {
    height: 424px;
    background-color: #000;
    position: relative;
}

.imgPng {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 200px;
    width: auto;
    z-index: 2000;
    img {
        height: 100%;
    }
}

.custom_tabs.spaeil,
/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
    height: 100%;
}
</style>