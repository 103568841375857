<template>
    <div class="playlist">
        <div class="playlist_item"
             @click="callMore(item)"
             :class="{active: active=== item.id}"
             v-for="item in list" :key="item.id">
            <div class="playlist_layout titles">
                <span>{{item.name}}</span>
                <i v-if="active!== item" class="iconvideo-b-luxiang iconfont"></i>
                <i v-else class="iconzhengzaibofang iconfont"></i>
            </div>
            <div class="playlist_layout">
                <span>{{item.liveDate}} {{item.start}} - {{item.end}}</span>
                <!--<span>播放：2123次</span>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "playlist",
    props: {
        list: {
            type: Array,
            default () {
                return []
            }
        },
        liveDetails: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        active () {
            return this.$route.query.id;
        }
    },
    methods: {
        callMore (item) {
            this.$emit('callMore', item);
        }
    },
    mounted(){
        // this.$utils.setDocumentTitle(this.liveDetails.name)
    },
    watch: {
        liveDetails: {
            handler(val) {
                if (
                    Object.prototype.toString.call(val) === '[Object Object]' &&
                      val.name
                ) {
                    this.$utils.setDocumentTitle(val.name)
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped lang="less">
    .playlist {
        padding: 0 40px;
        .playlist_item {
            padding: 28px 0;
            border-bottom: 2px solid #F5F5F5;
            &.active {
                span, i {
                    color: #1A78F9;
                }
                .titles {
                    span, i {
                        color: #1A78F9;
                    }
                }
            }
        }
        .playlist_layout {
            display: flex;
            justify-content: space-between;
            span {
                font-size: 20px;
                font-weight: 400;
                color: #9F9F9F;
                line-height: 20px;
            }
            &.titles {
                margin-bottom: 15px;
                span {
                    font-size: 28px;
                    font-weight: 400;
                    color: #353535;
                    line-height: 28px;
                }
                i {
                    color: #000000;
                }
            }
        }
    }
</style>