<template>
  <div class="courseIntro">
    <div class="courseIntro_header">
      <h6>{{ liveDetails ? liveDetails.name : courseDetail.name || '' }}</h6>
      <p>
        {{ liveDetails ? liveDetails.corpName : courseDetail.corpName || '' }}
      </p>
    </div>
    <div class="courseIntro_content">
      <p class="title" v-if="!liveDetails && courseDetail">课程详情</p>
      <p class="title" v-if="liveDetails">直播详情</p>
      <ul>
        <li v-if="liveDetails">
          <van-icon name="clock-o" class="icons" />
          <span
            >直播时间:
            {{ liveDetails.startTime + '-' + liveDetails.end || '' }}</span
          >
        </li>
        <li>
          <van-icon name="user-o" class="icons" />
          <span
            >主讲老师:
            {{
              liveDetails
                ? liveDetails.teacherName
                : courseDetail.teacherName || ''
            }}</span
          >
        </li>
        <li>
          <van-icon name="location-o" class="icons" />
          <span
            >授课地点:
            {{
              liveDetails ? liveDetails.venueName : courseDetail.venueName || ''
            }}</span
          >
        </li>
      </ul>
      <p class="description">
        {{
          liveDetails ? liveDetails.description : courseDetail.description || ''
        }}
      </p>
    </div>
    <div class="courseIntro_footer">
      <a href="https://www.zjlll.net/" target="_blank"
        >Copyright © 浙江学习网</a
      >
    </div>
    <div class="courseIntro_app">
      <div class="app-des">扫码安装App</div>
      <div class="app-box">
        <div class="img"></div>
        <div class="app-name">在浙学App</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'courseIntro',
  props: {
    courseDetail: {},
    liveDetails: {}
  },
  created() {
    console.log(this.courseDetail, 111)
    console.log(this.liveDetails, 222)
  },
  mounted() {
    // this.$utils.setDocumentTitle(this.liveDetails.name)
  },
  watch: {
    liveDetails: {
      handler(val) {
        if (
          Object.prototype.toString.call(val) === '[Object Object]' &&
          val.name
        ) {
          this.$utils.setDocumentTitle(val.name)
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped lang="less">
.courseIntro {
  background: #fff;
  .courseIntro_header {
    padding: 32px 40px;
    border-bottom: 16px solid #f5f5f5;
    h6 {
      font-size: 36px;
      font-weight: 600;
      color: #353535;
      line-height: 40px;
    }
    p {
      margin-top: 16px;
      font-size: 28px;
      font-weight: 400;
      color: #9f9f9f;
      line-height: 24px;
    }
    span {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .courseIntro_content {
    margin-top: 16px;
    padding: 26px 40px 40px;
    font-size: 24px;
    font-weight: 500;
    color: #353535;
    line-height: 40px;
    word-break: break-all;
    border-bottom: 16px solid #f5f5f5;
    .title {
      font-size: 36px;
      font-weight: 600;
      color: #353535;
      line-height: 40px;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        font-size: 28px;
        margin-top: 20px;
        .icons {
          color: #1a78f9;
          margin-right: 10px;
        }
      }
    }
    .description {
      font-size: 28px;
      color: #353535;
      line-height: 32px;
      margin-top: 20px;
    }
  }
  .courseIntro_footer {
    border-bottom: 16px solid #f5f5f5;
    text-align: center;
    a {
      color: #333;
      display: block;
      line-height: 100px;
    }
  }
  .courseIntro_app {
    padding-bottom: 30px;
    text-align: center;
    .app-des {
      margin: 30px;
    }
    .app-box {
      text-align: center;
      width: 300px;
      border: 1px solid #ddd;
      margin: 0 auto;
      .img {
        background: url('~@/assets/images/app.png') no-repeat;
        background-size: cover;
        width: 300px;
        height: 300px;
        display: block;
        content: '';
      }
      .app-name {
        line-height: 60px;
      }
    }
  }
}
</style>