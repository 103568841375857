export default {
    data () {
        return {
            id: this.$route.query.id,
            activeName: ''
        }
    },
    beforeMount () {
    },
    beforeDestroy () {
    },
    mounted () {
    },
    methods: {
        handleTabs (obj) {
            let str = '';

            let objs = {...this.$route.query, ...obj};

            for (let key in objs) {
                str += `${key}=${objs[key]}&`;
            }
            this.$router.replace(`${this.$route.path}?${str.slice(0, -1)}`)
        }
    },
    watch: {
        activeName (val) {
            val && this.handleTabs({tab: val});
        },
        liveTabs (obj) {
            this.handleTabs(obj);
        }
    },
    created () {
        const tab = this.$route.query.tab;

        if (tab) {
            this.activeName = tab;
        }
    }
}
